<template :key="$route.fullPath">
    <div class="wraper" >
        <Header :isShow="isShow" />
        <IndustryTop />
        <div class="content_box" ref="header" v-if="index!=3">
            <div class="content max_small_box">
                <div class="service_box" >
                    <div class="service max_small_box">
                        <div class="under">
                            <div class="service-swiper-container">
                                <div class="swiper-slide"  v-for="(item, index) in list" :key="index" data-aos="fade-up" :data-aos-delay="(index+1)*200">
                                    <router-link :to="`/industry/${item.third_navigation_id}${item.page_id}/${item.second_navigation_id}/${index}`">
                                        <div class="img">
                                            <img :src="imgUrl + item.image" alt="" />
                                        </div>
                                        <p class="title">{{item.name}}</p>
                                        <p class="more">查看更多</p>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
                        
        <div class="third max_small_box" v-if="index==3">
            <div class="text" v-for="(item, index) in thirdList" :key="index" @click="handleChangeThird(item.id, index)">
                <p :class="thirdIndex == index ? 'active' :''">{{item.name}}</p>
            </div>
        </div>


        <div class="searchBox" v-if="index==3">
            <div class="searchsmall">
                <input placeholder="搜索产品清单......" v-model="searchValue" @keyup.enter="onSubmit" />
                <p v-if="searchValue!=''" class="fourth text" @click="searchSubmit()">搜索</p>
                <p v-if="searchValue==''" class="fourth text">搜索</p>
            </div>
        </div>

        <div  v-if="index==3 && thirdIndex==0">
            <a-table class="max_small_box" style="margin-top: 50px;scrollbar-width: 66px;" :columns="columnsOne" :data-source="data" :pagination="false" rowKey="id" :scroll="{ x: 2000 , y: 500}">
                <template v-slot:num="slotProps">{{
                        (pageOne - 1) * 10 + slotProps.index + 1
                    }}</template>
            </a-table>
            <div class="pagination max_small_box">
                <a-pagination :default-current="pageOne" :total="last_page" @change="handleChangePage" />
            </div>
        </div>
        

        <div  v-if="index==3 && thirdIndex==1">
            <a-table class="max_small_box" style="margin-top: 50px;scrollbar-width: 66px;" :columns="columnsTwo" :data-source="data" :pagination="false" rowKey="id" :scroll="{ x: 2000 }">
                <template v-slot:num="slotProps">{{
                        (pageTwo - 1) * 10 + slotProps.index + 1
                    }}</template>
            </a-table>
            <div class="pagination max_small_box">
            <a-pagination :default-current="pageTwo" :total="last_page" @change="handleChangePage" />
        </div>
        </div>
        
        
    </div>
</template>

<script>
import { onBeforeMount, onMounted, onUnmounted, ref, reactive, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';
import IndustryTop from '../../components/industry/IndustryTop';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref('')

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//轮播图函数
const bannerEffect = (route) => {
    const banner = ref()

    const getBanner = async () =>  {
        await post('/carousel/get_carousel', {page_id: '/service_industry', second_navigation_id:route.params.second_id}).then((res) => {
            banner.value = res.data.images
        })
    }

    watchEffect(() => {
        if(route.params.second_id) {
            getBanner()
        }
    })

    return { getBanner, banner }

}

//列表处理函数
const listEffect = (route) => {
    const list = ref()

    const index = ref()

    // 三级导航
    const thirdList = ref()

    const thirdIndex = ref(0)
    const thirdID = ref(0)
    //////////////////////////////////

    //详情
    const detail = ref()

    //一的页数
    const pageOne = ref(1)
    //二的页数
    const pageTwo = ref(1)

    //一的总页数
    const last_page = ref()

    const columnsOne = [
        // {
        //     title: '质量技术服务大类代码',
        //     dataIndex: 'type_code',
        //     key: 'type_code',
        //     scopedSlots: { customRender: 'type_code' },
        //     align:'center',
        //     width:'110px',

        // },
        // {
        //     title: '质量技术服务大类名称',
        //     dataIndex: 'type_name',
        //     key: 'type_name',
        //     align:'center',
        //     width:'110px',
        // },
        // {
        //     title: '质量技术服务代码',
        //     dataIndex: 'quality_code',
        //     key: 'quality_code',
        //     align:'center',
        //     width:'100px',
        // },
        // {
        //     title: '质量技术服务名称',
        //     dataIndex: 'quality_name',
        //     key: 'quality_name',
        //     align:'center',
        //     width:'110px',
        // },
        // {
        //     title: '检测服务代码',
        //     dataIndex: 'service_code',
        //     key: 'service_code',
        //     align:'center',
        //     width:'80px',
        // },
        {
            title: "序号",
            slots: { customRender: "num" },
            align:'center',
            width:'100px',
        },
        {
            title: '检测服务名称',
            dataIndex: 'name',
            key: 'name',
            align:'center',
            width:'150px',
        },
        {
            title: '产品编码',
            dataIndex: 'product_id',
            key: 'product_id',
            align:'center',
            width:'130px',
        },
        {
            title: '行业分类',
            dataIndex: 'industry_type',
            key: 'industry_type',
            align:'center',
            width:'110px',
        },
        {
            title: '产品类别',
            dataIndex: 'product_type',
            key: 'product_type',
            align:'center',
            width:'90px',
        },
        {
            title: '产品内容',
            dataIndex: 'product',
            key: 'product',
            align:'center',
            width:'120px',
        },
        {
            title: '服务适用范围',
            dataIndex: 'apply_rank',
            key: 'apply_rank',
            align:'center',
            width:'130px',
        },
        {
            title: '产品简介(服务/检测依据)',
            dataIndex: 'detail',
            key: 'detail',
            width:'220px',
        },
        {
            title: '产品用途说明',
            dataIndex: 'description',
            key: 'description',
            width:'130px',
        },
        {
            title: '检测项目',
            dataIndex: 'test_item',
            key: 'test_item',
        },
        // {
        //     title: '样品要求',
        //     dataIndex: 'sample_request',
        //     key: 'sample_request',
        //     align:'center',
        //     width:'80px',
        // },
    ];

    const columnsTwo = [
        // {
        //     title: '质量技术服务大类代码',
        //     dataIndex: 'type_code',
        //     key: 'type_code',
        //     scopedSlots: { customRender: 'type_code' },
        //     align:'center',
        //     width:'110px',

        // },
        // {
        //     title: '质量技术服务大类名称',
        //     dataIndex: 'type_name',
        //     key: 'type_name',
        //     align:'center',
        //     width:'110px',
        // },
        // {
        //     title: '质量技术服务代码',
        //     dataIndex: 'quality_code',
        //     key: 'quality_code',
        //     align:'center',
        //     width:'100px',
        // },
        {
            title: "序号",
            slots: { customRender: "num" },
            align:'center',
            width:'100px',
        },
        {
            title: '质量技术服务名称',
            dataIndex: 'name',
            key: 'name',
            align:'center',
            width:'150px',
            
        },
        {
            title: '产品编码',
            dataIndex: 'product_id',
            key: 'product_id',
            align:'center',
            width:'110px',
        },
        {
            title: '行业分类',
            dataIndex: 'industry_type',
            key: 'industry_type',
            align:'center',
            width:'110px',
        },
        {
            title: '产品类别',
            dataIndex: 'product_type',
            key: 'product_type',
            align:'center',
            width:'90px',
        },
        {
            title: '产品内容',
            dataIndex: 'product',
            key: 'product',
            align:'center',
            width:'150px',
        },
        {
            title: '服务适用范围',
            dataIndex: 'apply_rank',
            key: 'apply_rank',
            align:'center',
            width:'120px',
        },
        {
            title: '产品简介(服务/检测依据)',
            dataIndex: 'detail',
            key: 'detail',
        },
        {
            title: '产品用途说明',
            dataIndex: 'description',
            key: 'description',
        },
        // {
        //     title: '样品要求',
        //     dataIndex: 'sample_request',
        //     key: 'sample_request',
        //     align:'center',
        //     width:'200px',
        // },

    ];
    const data = ref()
        
    const searchValue = ref('')  //搜索框的值

    /////////////////////////////////////

    //获取三级
    const getThirdNav = async (id, indextwo) => {
        let postData = {
            page_id: '/service_industry',
            second_navigation_id: id,
        }
        await post('/nav/get_third_nav', postData).then((res) => {
            // console.log('三级导航', res)
            // console.log('此时页数'+pageOne.value)
            // console.log('此时页数'+pageTwo.value)
            thirdID.value = res.data[indextwo].id
            thirdList.value = res.data
            getDetail(res.data[indextwo].id)
        })
    }

    const getServiceNav = async () => {
        try{
            await post('/Industry/get_service_list', {second_navigation_id: route.params.second_id}).then((res) => {
                // console.log('列表服务',res)
                list.value = res.data
                index.value = route.params.index
            })
        }catch(e) {
        
        }
    }

    //获取详情
    const getDetail = async (id) => {
        // console.log(id)
        // console.log('pageOne.value:'+pageOne.value)
        // console.log('pageOne.value:'+pageTwo.value)
        // 清单
        let postData=''
        if(id==43 || id=='43'){
            postData = {
                page: pageOne.value,
                third_navigation_id:id,
                name: searchValue.value
            }

        }else if(id==44 || id=='44'){
            postData = {
                page: pageTwo.value,
                third_navigation_id:id,
                name: searchValue.value
            }
        }
       
        try{
            await post('/industry/get_repertoire_list', postData).then((res) => {
                // console.log('清单列表', res)
                data.value = res.data.data

                // console.log('pageOne.value:'+pageOne.value)
                // console.log('pageTwo.value:'+pageTwo.value)

                last_page.value = res.data.total
            })
            
        }catch(e) {
        
        }
    }

    //跟换三级
    const handleChangeThird = (id, index) => {
        // console.log(id)
        thirdIndex.value = index
        thirdID.value = id
        searchValue.value = ''
        // console.log('pageOne.value：'+pageOne.value)
        // console.log('pageTwo.value：'+pageTwo.value)
        if(id==43){
            pageOne.value = 1
        }else if(id==44){
            pageTwo.value = 1
        }
        
        // console.log('更换3级头1：'+thirdIndex.value)
        getDetail(id)
    }
    //收费标准
    // const getList = async () => {
    //     let postData = {
    //         page: page.value
    //     }
    //     try{
    //         await post('/index/get_charge_standard', postData).then((res) => {
    //             // console.log('收费标准列表', res)
    //             data.value = res.data.data
    //             last_page.value = res.data.total
    //         })
            
    //     }catch(e) {
        
    //     }
    // }

    //更换页数
    const handleChangePage = (new_page, pageSize) =>{
        // console.log('更换页数的时候3级头：'+thirdIndex.value)

        // console.log('new_page：'+new_page)
        // console.log('pageOne.value：'+pageOne.value)
        // console.log('pageTwo.value：'+pageTwo.value)
        if(thirdIndex.value==0){
            pageOne.value = new_page
        }else if(thirdIndex.value==1){
            pageTwo.value = new_page
        }
        
        getDetail(thirdID.value)
    }

    // 搜索
    const searchSubmit = () =>{
        if(thirdID.value==43){
            pageOne.value = 1
        }else if(thirdID.value==44){
            pageTwo.value = 1
        }
        getDetail(thirdID.value)
        // console.log('按钮',searchValue.value)
    }
    
    //回车搜索
    const onSubmit = () => {
        searchSubmit(searchValue.value)
    }

    watchEffect(() => {
        // getList()
        if(route.params.second_id){
            getServiceNav()
        }
        if(route.params.second_id==51 || route.params.second_id=='51'){
            getThirdNav(route.params.second_id, route.params.indextwo)
            thirdIndex.value = route.params.indextwo
        }
    })

    return { list, getServiceNav, index, thirdList, thirdID, thirdIndex, handleChangeThird ,
    detail, getDetail, pageOne, pageTwo, last_page, handleChangePage, columnsOne,columnsTwo, data,
    searchSubmit, searchValue, onSubmit
    }
}

export default {
    name: 'Industry',
    components: { Header, Footer, FloatingFrame, IndustryTop },
    setup() {
        const route = useRoute()
        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        const { getBanner, banner } = bannerEffect(route)

        const { list, getServiceNav, index, thirdList, thirdID, thirdIndex, handleChangeThird,
        detail, getDetail, pageTwo,pageOne, last_page, handleChangePage, columnsOne,columnsTwo, data,
        searchSubmit, onSubmit, searchValue
         } = listEffect(route)

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        getBanner()
        getServiceNav()

        onUnmounted(() => {
            document.querySelector('body').setAttribute('style', 'background-color:#fff')
        })

        const { imgUrl }  = api

        return{ 
            isShow,
            header,
            banner,
            imgUrl,
            list,
            index, thirdList, thirdID, thirdIndex, handleChangeThird,
            detail, pageOne,pageTwo, last_page, handleChangePage, columnsOne,columnsTwo, data,
            onSubmit, searchSubmit, searchValue
         }
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
/* 轮播图 */
.banner{
    margin-top: 111px;
    @media screen and (max-width: 1200px){
        margin-top: $phone-banner-marginTop;
        height: $phone-banner-height;
    }
    img{
        width: 100%;
        /* margin: 0 auto; */
        display: block;
        @media screen and (max-width: 1200px){
            height: 100%;
            object-fit: cover;
        }
    }
}
.third{
    margin-top: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1200px){
        padding: 0 30px;
        margin-top: 50px;
    }
    .text{
        width: 48%;
        text-align: center;
        cursor: pointer;
        p{
            display: block;
            font-size: 16px;
            color: #666666;
            padding: 12px 0;
            border: 1px solid #dcdcdc;
            margin: 10px 0;
        }
        .active{
            color: #1f2c5c;
            border: 1px solid #1f2c5c;
        }
    }
}
/* 内容 */
.content_box{
    margin-top: 20px;
    background: #fff;
    .content{
        display: flex;
        padding: 30px 0 0 0;
        @media screen and (max-width: 1200px){
            display: block;
        }
        /* 服务 */
        .service_box{
            background: #fff;
            /* margin-top: 20px; */
            .service{
                .top{
                    padding: 0px 60px 0 60px;
                    .title{
                        color: rgba(48, 90, 168, 100);
                        font-size: 20px;
                        font-weight: bold;
                        text-align: center;
                    }
                    .line{
                        width: 100%;
                        height: 2px;
                        background: rgba(187, 187, 187, 100);
                        margin-top: 20px;
                    }
                }
                /* 电脑端 */
                .under{
                    margin-top: 40px;
                    @media screen and (max-width: 1200px){
                        /* display: none; */
                        padding: 0 30px;
                    }
                    .service-swiper-container {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        position: relative;
                        display: flex;
                        flex-wrap: wrap;
                        /* padding-bottom: 110px; */
                        .swiper-slide {
                            width: 22%;
                            margin-right: 3%;
                            margin-bottom: 30px;
                            text-align: center;
                            font-size: 18px;
                            background: #F4F4F4;
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: -webkit-flex;
                            display: flex;
                            -webkit-box-pack: center;
                            -ms-flex-pack: center;
                            -webkit-justify-content: center;
                            justify-content: center;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            -webkit-align-items: center;
                            align-items: center;
                            flex-direction: column;
                            cursor: pointer;
                            transition: .3s all;
                            &:nth-child(4n){
                                margin-right: 0%;
                            }
                            @media screen and (max-width: 900px){
                                width: 48%;
                                margin-right: 4%;
                                &:nth-child(4n){
                                    margin-right: 4%;
                                }
                                &:nth-child(2n){
                                    margin-right: 0%;
                                }
                            }
                            @media screen and (max-width: 900px){
                                width: 100%;
                                margin-right: 0%;
                                &:nth-child(4n){
                                    margin-right: 0%;
                                }
                                &:nth-child(2n){
                                    margin-right: 0%;
                                }
                            }
                            &:hover{
                                background: rgba(48, 90, 168, 100);
                                .title,.desc,.more{
                                    color: #fff;
                                }
                                .img{
                                    img{
                                        transform: scale(1.2);
                                    }
                                }
                            }
                            .img{
                                width: 100%;
                                height: 180px;
                                overflow: hidden;
                                @media screen and (max-width: 1200px){
                                    height: 170px;
                                }
                                img{
                                    transition: .3s all;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                            .title{
                                color: rgba(16, 16, 16, 100);
                                font-size: 16px;
                                font-weight: 600;
                                margin-top: 10px;
                            }
                            .desc{
                                color: rgba(16, 16, 16, 100);
                                font-size: 14px;
                                padding: 0 30px;
                                margin-top: 10px;
                                text-align: left;
                                line-height: 22px;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                            }
                            .more{
                                color: rgba(16, 16, 16, 100);
                                font-size: 14px;
                                border: 1px solid rgba(187, 187, 187, 100);
                                padding: 8px 15px;
                                margin: 20px auto 20px auto;
                                width: max-content;
                                @media screen and (max-width: 900px){
                                    font-size: .14rem;
                                }
                            }
                        }
                        .button-next{
                            right: 45%;
                            left: auto;
                            top: 90%;
                            position: absolute;
                            cursor: pointer;
                            transform: rotate(180deg);
                            font-size: 24px;
                        }
                        .button-prev{
                            left: 45%;
                            right: auto;
                            top: 90%;
                            position: absolute;
                            cursor: pointer;
                            font-size: 24px;
                        }
                    }
                }
                /* 手机端 */
                .under_two{
                    margin-top: 40px;
                    display: none;
                    @media screen and (max-width: 1200px){
                        /* display: block; */
                    }
                    .service-swiper-container-two {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        position: relative;
                        padding-bottom: 50px;
                        .swiper-slide {
                            text-align: center;
                            font-size: 18px;
                            background: #F4F4F4;
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: -webkit-flex;
                            display: flex;
                            -webkit-box-pack: center;
                            -ms-flex-pack: center;
                            -webkit-justify-content: center;
                            justify-content: center;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            -webkit-align-items: center;
                            align-items: center;
                            flex-direction: column;
                            cursor: pointer;
                            transition: .3s all;
                            &:hover{
                                background: rgba(48, 90, 168, 100);
                                .title,.desc,.more{
                                    color: #fff;
                                }
                                .img{
                                    img{
                                        transform: scale(1.2);
                                    }
                                }
                            }
                            .img{
                                width: 100%;
                                height: 200px;
                                overflow: hidden;
                                img{
                                    transition: .3s all;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }
                            .title{
                                color: rgba(16, 16, 16, 100);
                                font-size: 20px;
                                font-weight: 600;
                                margin-top: 10px;
                            }
                            .desc{
                                color: rgba(16, 16, 16, 100);
                                font-size: 14px;
                                padding: 0 30px;
                                margin-top: 10px;
                                text-align: left;
                                line-height: 22px;
                                margin-bottom: 20px;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                            }
                            .more{
                                color: rgba(16, 16, 16, 100);
                                font-size: 1px;
                                border: 1px solid rgba(187, 187, 187, 100);
                                padding: 8px 15px;
                                margin: 30px auto 20px auto;
                                width: max-content;
                            }
                        }
                        .button-next{
                            right: 45%;
                            left: auto;
                            top: 90%;
                            position: absolute;
                            cursor: pointer;
                            transform: rotate(180deg);
                            font-size: 24px;
                        }
                        .button-prev{
                            left: 45%;
                            right: auto;
                            top: 90%;
                            position: absolute;
                            cursor: pointer;
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
}
.pagination{
    display: block;
    text-align: center;
    padding-top: 80px;
}

.searchBox{
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 40px;
    .searchsmall{
        width: 100%;
        display: flex;
        align-items: center;
        @media screen and (min-width: 1200px){
            max-width: 1200px;
            margin-left: auto;
            margin-right: auto;
        }
        @media screen and (max-width: 1200px){
            width: 100%;
            padding: 0 30px;
        }
    }
    input{
        width: 100%;
        height: 50px;
        outline: none;
        border: 1px solid rgba(187, 187, 187, 100);
        padding: 15px 10px;
        color: #868686;
        font-size: 16px;
        /* margin-right: 20px; */
        @media screen and (max-width: 900px){
            font-size: .14rem;
            width: calc(100% - 60px);
        }
    }

    .fourth{
        text-align: center;
        width: 100px;
        height: 50px;
        background-color: rgba(47, 80, 158, 100);
        border: 1px solid rgba(47, 80, 158, 100);
        font-size: 16px;
        /* border: none; */
        color: #fff;
        padding: 15px 0;
        cursor: pointer;
        @media screen and (max-width: 900px){
            font-size: .14rem;
        }
    }

}
</style>
